import { HashLink, HashLinkProps } from 'react-router-hash-link'
import {
  Box,
  Button,
  Flex,
  HStack,
  useColorModeValue as mode,
} from '@chakra-ui/react'
// import { ColorModeSwitcher } from '../../ColorModeSwitcher'
import { Logo } from '../../atoms/Logo'
import { MobileNav } from '../MobileNav'
import { NavLink } from '../../atoms/NavLink'
import urls from 'base/paths'
import { Link } from 'react-router-dom'
import GlobalContext from 'contexts/GlobalContext'
import { ReactNode, useContext } from 'react'
import { useWallet } from 'hooks/useWallet'
import { WalletIsConnected } from 'components/organisms/WalletIsConnected'
import { useDepositInfo } from 'hooks/useDepositInfo'

function scrollCustomFunction(el: HTMLElement) {
  const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset
  const yOffset = -90
  window.scrollTo({
    top: yCoordinate + yOffset,
    behavior: 'smooth',
  })
}

interface HashLinkCustomProps extends HashLinkProps {
  // isActive?: boolean
  children: ReactNode
}

const HashLinkCustom = ({ to, children }: HashLinkCustomProps) => (
  <HashLink to={to} smooth scroll={scrollCustomFunction}>
    <NavLink.Desktop>{children}</NavLink.Desktop>
  </HashLink>
)

export const NavBar = () => {
  const { navRef } = useContext(GlobalContext)
  const { balance } = useDepositInfo()
  useWallet()

  return (
    <Box as="header" position="sticky" top="0" zIndex="4">
      <Box bg={mode('white', 'gray.800')} borderBottomWidth="1px">
        <Box maxW="7xl" mx="auto" py="4" px={{ base: '6', md: '8' }}>
          <Flex as="nav" justify="space-between">
            <HStack spacing="8">
              <Box as="a" href="/" rel="home">
                <Logo />
              </Box>
              <HStack display={{ base: 'none', lg: 'flex' }} spacing="8">
                <HashLinkCustom to={`${urls.home}/#howItWorks`}>
                  How does it works
                </HashLinkCustom>
                <HashLinkCustom to={`${urls.home}/#activities`}>
                  Activities
                </HashLinkCustom>
                <HashLinkCustom to={`${urls.home}/#faq`}>FAQ</HashLinkCustom>
                <HashLinkCustom to={`${urls.home}/#report`}>
                  Report an Issue
                </HashLinkCustom>
              </HStack>
            </HStack>
            <Flex align="center">
              <HStack spacing="8" display={{ base: 'none', md: 'flex' }}>
                <WalletIsConnected>
                  <Link to={urls.deposit}>
                    <NavLink.Desktop>Deposit</NavLink.Desktop>
                  </Link>
                  {balance > 0 && (
                    <Link to={urls.withdraw}>
                      <NavLink.Desktop>Withdraw</NavLink.Desktop>
                    </Link>
                  )}
                  <Button colorScheme="brand" to={urls.trade} as={Link}>
                    Trade
                  </Button>
                </WalletIsConnected>
              </HStack>
              <Box ml="5">
                <MobileNav />
              </Box>
              {/* <ColorModeSwitcher justifySelf="flex-end" /> */}
            </Flex>
          </Flex>
        </Box>
      </Box>
      <Box ref={navRef} />
    </Box>
  )
}
