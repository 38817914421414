import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import {
  fetchTrade,
  fetchTransferToVault,
  fetchWithdraw,
} from 'services/blocks'
import { RootState } from 'store'
import { STATUS } from 'utils/status'

const blocksAdapter = createEntityAdapter<{ id: number }>()

export const withdrawSlice = createSlice({
  name: 'withdraw',
  initialState: blocksAdapter.getInitialState({
    status: STATUS.IDLE,
    withdrawStatus: STATUS.IDLE,
  }),
  reducers: {
    add: blocksAdapter.addOne,
    remove: blocksAdapter.removeOne,
    clearList: blocksAdapter.removeAll,
    clear: (state) => {
      state.status = STATUS.IDLE
      state.withdrawStatus = STATUS.IDLE
      blocksAdapter.removeAll(state)
    },
    clearError: (state) => {
      state.status = STATUS.IDLE
      state.withdrawStatus = STATUS.IDLE
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTransferToVault.pending, (state) => {
      state.status = STATUS.PENDING
    })
    builder.addCase(fetchTransferToVault.fulfilled, (state) => {
      state.status = STATUS.SUCCESS
    })
    builder.addCase(fetchTransferToVault.rejected, (state) => {
      state.status = STATUS.ERROR
    })
    builder.addCase(fetchTrade.pending, (state) => {
      state.status = STATUS.PENDING
    })
    builder.addCase(fetchTrade.fulfilled, (state) => {
      state.status = STATUS.SUCCESS
    })
    builder.addCase(fetchTrade.rejected, (state) => {
      state.status = STATUS.ERROR
    })
    builder.addCase(fetchWithdraw.pending, (state) => {
      state.withdrawStatus = STATUS.PENDING
    })
    builder.addCase(fetchWithdraw.fulfilled, (state) => {
      state.withdrawStatus = STATUS.SUCCESS
    })
    builder.addCase(fetchWithdraw.rejected, (state) => {
      state.withdrawStatus = STATUS.ERROR
    })
  },
})

export const blocksVaultSelectors = blocksAdapter.getSelectors<RootState>(
  (state) => state.withdraw
)

export default withdrawSlice.reducer
