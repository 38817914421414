import { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { RiImage2Fill } from 'react-icons/ri'
import {
  Box,
  Button,
  Center,
  Heading,
  Icon,
  LightMode,
  SimpleGrid,
  Text,
  Link as CKLink,
} from '@chakra-ui/react'
import urls from 'base/paths'
import { WalletIsConnected } from 'components/organisms/WalletIsConnected'
import { useDepositInfo } from 'hooks/useDepositInfo'
import bg from './image/bg.png'
import { useAppSelector } from 'hooks/redux'
import { getBlocksTotal } from 'services/blocks'
import EthersContext from 'contexts/EtherInstances'
import { OPENSEA_URL, TRADABLE_CORE_ADDRESS } from 'base/dotenv'
import { ExternalLinkIcon } from '@chakra-ui/icons'

export const Hero = () => {
  const [vaultBalance, setVaultBalance] = useState('-')
  const [tradeCount, setTradeCount] = useState('-')
  const { balance } = useDepositInfo()
  const { blocksContractRead, tradableContractRead } = useContext(EthersContext)
  const price = useAppSelector((state) => state.deposit.price)

  useEffect(() => {
    async function fetchVaultBalance() {
      const blocksVaultBalance = await getBlocksTotal({
        contract: blocksContractRead,
        userAddress: TRADABLE_CORE_ADDRESS,
      })
      setVaultBalance(blocksVaultBalance)
      const tradeList = await tradableContractRead.queryFilter(
        tradableContractRead.filters.Trade()
      )
      setTradeCount(tradeList.length.toString())
    }
    fetchVaultBalance()
  }, [blocksContractRead, tradableContractRead])

  return (
    <Box
      as="section"
      bg="gray.800"
      py="12"
      position="relative"
      h={{ base: '560px', md: '640px' }}
      bgImage={`url(${bg})`}
      bgSize="cover"
      bgPosition="center"
      _after={{
        content: `""`,
        display: 'block',
        w: 'full',
        h: 'full',
        bg: 'blackAlpha.800',
        position: 'absolute',
        inset: 0,
        zIndex: 0,
      }}
    >
      <Box
        maxW={{ base: 'xl', md: '7xl' }}
        mx="auto"
        px={{ base: '6', md: '8' }}
        h="full"
        zIndex={1}
        position="relative"
      >
        <Center
          flexDirection="column"
          textAlign="center"
          color="white"
          h="full"
        >
          <Heading size="2xl" fontWeight="extrabold">
            Trade Blocks to create unique Panels
          </Heading>
          <Text fontSize="lg" fontWeight="medium" mt="3">
            Trade unwanted Blocks or Deposit to earn Blocks in the future
          </Text>
          <LightMode>
            <Box display={'flex'} mt="3">
              <WalletIsConnected>
                <Button
                  colorScheme="brand"
                  size="lg"
                  fontSize="md"
                  as={Link}
                  to={urls.trade}
                >
                  Trade
                </Button>
                <Button
                  colorScheme={'white'}
                  size="lg"
                  fontSize="md"
                  ml={6}
                  variant={'outline'}
                  as={Link}
                  to={urls.deposit}
                >
                  Deposit
                </Button>
                {balance > 0 && (
                  <Button
                    colorScheme={'white'}
                    size="lg"
                    fontSize="md"
                    ml={6}
                    variant={'link'}
                    as={Link}
                    to={urls.withdraw}
                  >
                    Withdraw
                  </Button>
                )}
              </WalletIsConnected>
            </Box>
          </LightMode>
        </Center>
      </Box>
      <Box
        display={{ base: 'none', md: 'block' }}
        position="absolute"
        zIndex={2}
        w="full"
        bottom="0"
        py="4"
        bg="blackAlpha.400"
      >
        <Box maxW={{ base: 'xl', md: '7xl' }} mx="auto">
          <SimpleGrid columns={{ base: 1, md: 4 }}>
            <Box textAlign="center" color="white">
              <CKLink
                isExternal
                href={`${OPENSEA_URL}${TRADABLE_CORE_ADDRESS}`}
                textDecor={'underline'}
              >
                <Text>
                  Trade Blocks Vault{' '}
                  <ExternalLinkIcon mx="4px" mt={-1} fontSize={'sm'} />
                </Text>
              </CKLink>
              <Text fontSize="3xl">{vaultBalance}</Text>
            </Box>
            <Box textAlign="center" color="white">
              <Text>Trade transactions</Text>
              <Text fontSize="3xl">{tradeCount}</Text>
            </Box>
            <Box textAlign="center" color="white">
              <Text>Trade fee</Text>
              <Text fontSize="3xl">
                <Icon as={RiImage2Fill} h={5} />
              </Text>
            </Box>
            <Box textAlign="center" color="white">
              <Text>Deposit fee</Text>
              <Text fontSize="2xl">
                <Icon as={RiImage2Fill} h={5} /> {price > 0 && `+ Ξ${price}`}
              </Text>
            </Box>
          </SimpleGrid>
        </Box>
      </Box>
    </Box>
  )
}
