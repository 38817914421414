import { TRADABLE_CORE_ADDRESS } from 'base/dotenv'
import EthersContext from 'contexts/EtherInstances'
import { useCallback, useContext, useEffect, useRef } from 'react'
import { fetchBlocks, fetchVaultBlocks } from 'services/blocks'
import { useAppDispatch } from './redux'

const DEFAULT_TIMER = 10000

export const useRefreshBlocks = (
  refreshVault = true,
  refreshMyBlocks = true
) => {
  const { blocksContractSigned, userAddress } = useContext(EthersContext)
  const dispatch = useAppDispatch()
  const timer = useRef<undefined | NodeJS.Timeout>(undefined)

  const loadVaultBlocks = useCallback(() => {
    if (refreshVault) {
      dispatch(
        fetchVaultBlocks({
          contract: blocksContractSigned,
          userAddress: TRADABLE_CORE_ADDRESS,
        })
      )
    }
    if (refreshMyBlocks) {
      dispatch(
        fetchBlocks({
          contract: blocksContractSigned,
          userAddress,
        })
      )
    }
  }, [
    blocksContractSigned,
    dispatch,
    refreshMyBlocks,
    refreshVault,
    userAddress,
  ])

  useEffect(() => {
    timer.current = setInterval(() => loadVaultBlocks(), DEFAULT_TIMER)
    return () => {
      if (typeof timer.current !== 'undefined') {
        clearInterval(timer.current)
      }
    }
  }, [loadVaultBlocks])

  return [loadVaultBlocks]
}
