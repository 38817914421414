import { Box, Heading, Link, List, ListIcon, ListItem } from '@chakra-ui/react'
import { Link as RouterLink } from 'react-router-dom'
import { ETHERSCAN_URL, OPENSEA_URL, TRADABLE_CORE_ADDRESS } from 'base/dotenv'
import urls from 'base/paths'
import { AiOutlineRightSquare } from 'react-icons/ai'

export const HowDepositWorks = () => {
  return (
    <Box my={20} id="howDepositWorks">
      <Heading size="2xl" textAlign={'center'}>
        How does it works?
      </Heading>
      <Box
        display={'flex'}
        justifyContent={'space-between'}
        flexWrap={'wrap'}
        maxW={'container.lg'}
        px="5"
        mx={'auto'}
        mt={'10'}
      >
        <Box w={{ base: '100%', md: '45%' }}>
          <List spacing={3} textAlign={'left'}>
            <ListItem>
              <ListIcon as={AiOutlineRightSquare} color="brand.300" />
              You select all Blocks you want to deposit on TB Vault. Max 25 per
              tx.
            </ListItem>
            <ListItem>
              <ListIcon as={AiOutlineRightSquare} color="brand.300" />1 of these
              Blocks + ether fee will be charged. This Block goes to current
              depositors and ether fee goes to the project vault.
            </ListItem>
            <ListItem>
              <ListIcon as={AiOutlineRightSquare} color="brand.300" />
              The blocks deposited will be transfered to TB Vault (the smart
              contract address). You can see all deposited Blocks on{' '}
              <Link
                isExternal
                href={`${OPENSEA_URL}${TRADABLE_CORE_ADDRESS}`}
                textDecor={'underline'}
              >
                OpenSea
              </Link>
              .
            </ListItem>
            <ListItem>
              <ListIcon as={AiOutlineRightSquare} color="brand.300" />
              You won't have the deposited Blocks in your wallet anymore. You
              will have a balance stat on{' '}
              <Link
                isExternal
                href={`${ETHERSCAN_URL}address/${TRADABLE_CORE_ADDRESS}#code`}
                textDecor={'underline'}
              >
                TB Smart Contract
              </Link>
              .
            </ListItem>
          </List>
        </Box>
        <Box w={{ base: '100%', md: '45%' }} mb={10}>
          <List spacing={3} textAlign={'left'}>
            <ListItem>
              <ListIcon as={AiOutlineRightSquare} color="brand.300" />
              After you transaction is confirmed, you start earning shares from
              new deposits and trades.
            </ListItem>
            <ListItem>
              After your deposit transaction is confirmed, you will see balance
              stats on the deposit and withdraw page.
            </ListItem>
            <ListItem>
              <ListIcon as={AiOutlineRightSquare} color="brand.300" />
              You can withdraw Blocks any time, when have a balance.
            </ListItem>
            <ListItem>
              <ListIcon as={AiOutlineRightSquare} color="brand.300" />
              You choose the Blocks you want withdraw
            </ListItem>
            <ListItem>
              <ListIcon as={AiOutlineRightSquare} color="brand.300" />
              Check the{' '}
              <Link
                as={RouterLink}
                to={`${urls.home}#faq`}
                textDecor={'underline'}
              >
                FAQ section
              </Link>{' '}
              to be sure of your deposit and how this tool works.
            </ListItem>
          </List>
        </Box>
      </Box>
    </Box>
  )
}
