import { Box, Stack } from '@chakra-ui/react'
import { Copyright } from './Copyright'
import { Logo } from 'components/atoms/Logo'
import { SocialMediaLinks } from './SocialMediaLinks'
import { useContext } from 'react'
import GlobalContext from 'contexts/GlobalContext'

export const Footer = () => {
  const { footerRef } = useContext(GlobalContext)
  return (
    <>
      <Box ref={footerRef} position="sticky" bottom="0" zIndex="4" />
      <Box
        as="footer"
        role="contentinfo"
        py="4"
        px={{ base: '4', md: '8' }}
        // bgColor={'black'}
        borderTop={'1px solid black'}
        borderTopColor={'brand.200'}
      >
        <Stack maxW="7xl" mx="auto">
          <Stack
            direction="row"
            spacing="4"
            align="center"
            justify="space-between"
            alignContent={'center'}
          >
            <Box>
              <Logo />
              <Copyright alignSelf={{ base: 'center', sm: 'start' }} mt={4} />
            </Box>
            <SocialMediaLinks />
          </Stack>
        </Stack>
      </Box>
    </>
  )
}
