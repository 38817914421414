import {
  Box,
  Button,
  Heading,
  Link,
  Stack,
  Text,
  useColorModeValue as mode,
} from '@chakra-ui/react'
import { ExternalLinkIcon } from '@chakra-ui/icons'

export const ReportIssue = () => {
  return (
    <Box as="section" py="12" bg={mode('gray.50', 'gray.800')} id="report">
      <Stack
        spacing="6"
        direction={{ base: 'column', md: 'row' }}
        align={{ md: 'center' }}
        justify="space-between"
        maxW={{ base: 'xl', md: '7xl' }}
        mx="auto"
        px={{ base: '6', md: '8' }}
      >
        <Box>
          <Text
            mb="2"
            fontSize="lg"
            fontWeight="bold"
            color={mode('gray.600', 'gray.400')}
          >
            Bug, question or feedback
          </Text>
          <Heading size="xl" fontWeight="extrabold" maxW="20ch">
            Send it on my{' '}
            <Link
              href="https://github.com/arodundef"
              isExternal
              target={'_blank'}
            >
              Github
              <ExternalLinkIcon mx="4px" fontSize={'xl'} />
            </Link>{' '}
            or{' '}
            <Link
              href="https://twitter.com/Arod_tt"
              isExternal
              target={'_blank'}
            >
              Twitter
              <ExternalLinkIcon mx="4px" fontSize={'xl'} />
            </Link>
          </Heading>
        </Box>
        <Stack
          direction={{ base: 'column', sm: 'row' }}
          spacing={{ base: '2', sm: '4' }}
        >
          <Button
            size="lg"
            colorScheme="brand"
            as={'a'}
            href="https://github.com/arodundef/arod/issues"
          >
            Report an Issue
          </Button>
          <Button
            size="lg"
            as={'a'}
            href="https://github.com/arodundef/arod/discussions/3"
            variant={'outline'}
            colorScheme={'brand'}
          >
            Feedbacks or Questions
          </Button>
        </Stack>
      </Stack>
    </Box>
  )
}
