import {
  MouseEventHandler,
  RefObject,
  useCallback,
  useContext,
  useEffect,
} from 'react'
import {
  Box,
  Button,
  Collapse,
  Heading,
  Link,
  Portal,
  Stack,
  StackItem,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { ETHERSCAN_URL, TRADABLE_CORE_ADDRESS } from 'base/dotenv'
import EthersContext from 'contexts/EtherInstances'
import { depositSlice } from 'features/blocks/depositSlice'
import { STATUS } from 'utils/status'
import { withdrawSlice } from 'features/blocks/withdrawSlice'
import { useAppDispatch, useAppSelector } from 'hooks/redux'
import { fetchTrade, fetchVaultBlocks } from 'services/blocks'
import { CartDrawer } from '../CartDrawer'
import { BlockListSection } from './BlockListSection'
import { DinamycInfoText } from './DinamycInfoText'
import { WalletIsConnected } from '../WalletIsConnected'
import { HStackItemList } from 'components/atoms/HStackItemList'
import { useRefreshBlocks } from 'hooks/useRefreshBlocks'
import { useToastUpdate } from 'hooks/useToastUpdate'

const BLOCKS_SELECTED_LIMIT = 25

interface TradeBlocksProps {
  isMoveApproved: Boolean
  onMoveApproval: MouseEventHandler<HTMLButtonElement>
  navRef: RefObject<HTMLElement | null> | undefined | null
}

export const TradeBlocks = ({
  isMoveApproved,
  onMoveApproval,
  navRef,
}: TradeBlocksProps) => {
  useRefreshBlocks()
  useToastUpdate()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const dispatch = useAppDispatch()
  const toast = useToast()
  const { blocksContractSigned: contract, tradableContractSigned } =
    useContext(EthersContext)
  const tradeStatus = useAppSelector<STATUS>((state) => state.withdraw.status)
  const blocksWallet = useAppSelector((state) => state.myBlocks.list)
  const blocksVault = useAppSelector((state) => state.vaultBlocks.list)
  const selectedBlocksWallet = useAppSelector<number[]>(
    (state) => state.deposit.ids as number[]
  )
  const selectedBlocksVault = useAppSelector<number[]>(
    (state) => state.withdraw.ids as number[]
  )
  const isValidTrade =
    selectedBlocksVault.length > 0 &&
    selectedBlocksVault.length === selectedBlocksWallet.length - 1
  const selectedBlocks =
    selectedBlocksVault.length + selectedBlocksWallet.length

  const onSelectVault = useCallback(
    (id) => () => {
      if (selectedBlocksVault.includes(id)) {
        dispatch(withdrawSlice.actions.remove(id))
        return
      }
      if (
        selectedBlocksWallet.length + 1 + selectedBlocksVault.length >
        BLOCKS_SELECTED_LIMIT
      ) {
        return
      }
      dispatch(withdrawSlice.actions.add({ id }))
    },
    [dispatch, selectedBlocksVault, selectedBlocksWallet.length]
  )

  const onSelectWallet = useCallback(
    (id) => () => {
      if (selectedBlocksWallet.includes(id)) {
        dispatch(depositSlice.actions.remove(id))
        return
      }
      if (
        selectedBlocksWallet.length + 1 + selectedBlocksVault.length >
        BLOCKS_SELECTED_LIMIT
      ) {
        return
      }
      dispatch(depositSlice.actions.add({ id }))
    },
    [dispatch, selectedBlocksVault.length, selectedBlocksWallet]
  )

  const onDepositConfirm = useCallback(
    (donation: number) => () => {
      dispatch(
        fetchTrade({
          contract: tradableContractSigned,
          userBlocks: selectedBlocksWallet,
          vaultBlocks: selectedBlocksVault,
          donation,
        })
      )
    },
    [
      dispatch,
      selectedBlocksVault,
      selectedBlocksWallet,
      tradableContractSigned,
    ]
  )

  useEffect(() => {
    if (
      isOpen &&
      selectedBlocksVault.length === 0 &&
      selectedBlocksWallet.length === 0
    ) {
      onClose()
    }
  }, [isOpen, onClose, selectedBlocksVault.length, selectedBlocksWallet.length])

  useEffect(() => {
    dispatch(
      fetchVaultBlocks({
        contract,
        userAddress: TRADABLE_CORE_ADDRESS,
      })
    )
  }, [contract, dispatch])

  useEffect(() => {
    if (selectedBlocks < 1) return
    if (tradeStatus === STATUS.SUCCESS) {
      toast({
        title: 'Trade approved',
        description: `As soon as your transaction is confirmed, you will have them in your wallet.`,
        status: 'success',
        duration: 10000,
        isClosable: true,
      })
      onClose()
      dispatch(withdrawSlice.actions.clear())
      dispatch(depositSlice.actions.clear())
      return
    }
    if (tradeStatus === STATUS.PENDING) {
      toast({
        title: 'Trade',
        description: 'Approve the transaction on metamask',
        status: 'info',
        duration: 3000,
        isClosable: true,
      })
      return
    }
    if (tradeStatus === STATUS.ERROR) {
      toast({
        title: 'Trade',
        description: 'A error happened. Check your wallet and try again.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
      dispatch(withdrawSlice.actions.clearError())
    }
  }, [dispatch, onClose, selectedBlocks, toast, tradeStatus])

  useEffect(() => {
    return () => {
      dispatch(depositSlice.actions.clear())
      dispatch(withdrawSlice.actions.clear())
    }
  }, [dispatch])

  return (
    <>
      {!isMoveApproved && (
        <Stack py={10} px={8} spacing={10}>
          <StackItem textAlign={'center'}>
            <Heading size={'lg'}>First time trading?</Heading>
          </StackItem>
          <StackItem textAlign={'center'}>
            <WalletIsConnected>
              <Text>
                You must permit the contract to move your Blocks when trading.
                (No Blocks will be moved in this step)
              </Text>
              <Button
                colorScheme={'brand'}
                variant={'outline'}
                onClick={onMoveApproval}
                mt={10}
              >
                Set approval for the Contract
              </Button>
            </WalletIsConnected>
          </StackItem>
        </Stack>
      )}
      {isMoveApproved && (
        <Box display={'flex'} flexWrap={'wrap'}>
          <BlockListSection
            title={'Select the TB Vault Blocks'}
            blocks={blocksVault}
            onSelectBlock={onSelectVault}
            selectedBlocks={selectedBlocksVault}
          />
          <BlockListSection
            title={'Select your Wallet Blocks to trade'}
            blocks={blocksWallet}
            onSelectBlock={onSelectWallet}
            selectedBlocks={selectedBlocksWallet}
          />
        </Box>
      )}
      {navRef && (
        <Portal containerRef={navRef}>
          <Collapse in={selectedBlocks > 0} animateOpacity>
            <Box bgColor={'blackAlpha.900'} py={4}>
              <Box
                maxW={'7xl'}
                mx="auto"
                textAlign={'right'}
                px={{ base: '6', md: '8' }}
                display={'flex'}
                alignItems={'center'}
                justifyContent={'space-between'}
              >
                <Text textColor={'white'}>
                  Trading{' '}
                  <DinamycInfoText isValid={isValidTrade}>
                    {selectedBlocksVault.length}(
                    {selectedBlocksWallet.length - 1}) Blocks from the Vault
                  </DinamycInfoText>{' '}
                  to{' '}
                  <DinamycInfoText isValid={isValidTrade}>
                    {selectedBlocksWallet.length}(
                    {selectedBlocksVault.length + 1}) Blocks from your Wallet
                  </DinamycInfoText>
                </Text>
                <DinamycInfoText
                  isValid={selectedBlocks < BLOCKS_SELECTED_LIMIT}
                  fontStyle={'normal'}
                  fontWeight={'normal'}
                >
                  {selectedBlocks}/{BLOCKS_SELECTED_LIMIT} Selected Blocks
                </DinamycInfoText>
                <Button ml={4} onClick={onOpen} disabled={!isValidTrade}>
                  Next
                </Button>
              </Box>
            </Box>
          </Collapse>
        </Portal>
      )}
      <CartDrawer
        blocksUser={selectedBlocksWallet}
        blocksVault={selectedBlocksVault}
        onClose={onClose}
        isOpened={isOpen}
        onConfirm={onDepositConfirm}
        status={tradeStatus}
        title={
          <>
            <Heading size="md">
              Trading {selectedBlocksWallet.length} to{' '}
              {selectedBlocksVault.length} Blocks
            </Heading>
            <Heading size="sm">Blocks offered</Heading>
          </>
        }
        feeText={'1 Block'}
        dialogText={
          <>
            <HStackItemList>
              Blocks are moved only when the transaction is confirmed. We can't
              guarantee the availability of selected Blocks from the TB Vault,
              and it can cause a failed transaction. The rule is first come,
              first served.
            </HStackItemList>
            <HStackItemList>We don't reimburse lost fees.</HStackItemList>
            <HStackItemList>
              You can check the contract any time on this{' '}
              <Link
                isExternal
                href={`${ETHERSCAN_URL}address/${TRADABLE_CORE_ADDRESS}#code`}
                textDecor={'underline'}
              >
                link
              </Link>{' '}
              to be sure of your decision to trade Blocks.
            </HStackItemList>
          </>
        }
      />
    </>
  )
}
